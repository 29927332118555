/**
 * @description 用于辉瑞决定是否展示MAPP的入口
 * @author
 * @date
 */
import { httpService} from "@/services/httpService";

const filterMappOut = (options) => {
    return options.filter(i => i.eventTypeCode !== 'MAPP')
}

export default function (options) {
    const params = {
        "Meta": {
        },
        "Code": "pfizer-mapp-pilot"
    }
    return new Promise(resolve => {
        httpService.PfizerHasMappOrNot(params).then(res => {
            if(res.success) {
                const flag = res.content.pilot; // 1展示出来 0隐藏
                if(flag === '1'){
                    resolve(options);
                }else {
                    resolve(filterMappOut(options));
                }
            }
        })
    })

}
