<template>
    <div v-if="setup">
        <div class="chooseEventType">
            <div class="chooseEventTypeTag">
                <p style="font-size: 24px; font-weight: bold">会议入口</p>
                <div>CONFERENCE ENTRANCE</div>
            </div>
            <img :src="backgroundImg" class="chooseEventTypeImg"/>
        </div>
        <div class="cnt">
            <template v-for="(item, index) of options.options">
                <div class="cnt_other" :key="index"
                     v-if="item.eventTypeCode === 'groupMeeting' && getCookie('tenant') === 'pfizer'">其他
                </div>
                <div class="cnt-item" :key="index" @click="onSelectEventType(item)">
                    <div class="left-icon">
                        <i-icon :size="item.iconSize" :color="item.iconColor" :name="item.icon"/>
                    </div>
                    <div class="right-box">
                        <div class="title">{{ item.eventTypeName }}</div>
                        <div class="desc">{{ item.eventTypeTitle }}</div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import * as tinyCookie from "tiny-cookie";
    import {httpService} from "../services/httpService";
    import {getCookie} from "tiny-cookie";
    import getCategory from "@/utils/getCategory";
    
    export default {
        name: "chooseEventType",
        data() {
            return {
                getCookie,
                options: {},
                groupMeetingOptions: {},
                backgroundImg: '',
                setup: false
            };
        },
        async beforeRouteEnter(to, from, next) {
            const params = {
                collection: "cfg-ievent-nav",
                filter: {
                    tenantCode: tinyCookie.get("tenant"),
                    tenantId: tinyCookie.get("tenant_id"),
                },
            };
            httpService.GetSettings(params).then((response) => {
                if (response?.content?.options.find((x) => x.txt == "首页")) {
                    next();
                } else {
                    next(vm => {
                        vm.$smartStorage.remove('homeQueryListExpr')
                        if (response?.content?.options[0]?.homeQueryExpr) {
                            vm.$smartStorage.set("homeQueryListExpr", response?.content?.options[0]?.homeQueryExpr)
                        }
                        vm.$router.push("/eventList")
                    })
                }
            });
        },
        async created() {
            this.$smartStorage.remove("homeQueryListExpr");
            const res = await getCategory();
            if (res && res.success && res.content) {
                this.setup = true
                this.options = res.content;
                this.$smartStorage.set('homePageConfig', res.content.options);
                this.backgroundImg = res.content.backgroundImg || '';
                // 保存会议类型tab
                res.content.options.find((x) => {
                    this.$smartStorage.set(x.eventTypeCode + "EventTabs", x.eventStatus);
                });
                
            }
        },
        methods: {
            // 辉瑞-会议中心的权限
            isPfizerConferenceCenterPermission() {
                return new Promise(resolve => {
                    const params = {
                        "db": "smartx_tpm",
                        "collection": "cfg_rule_node",
                        "filter": {
                            "NodeCode": {
                                "$in": ["EventGroup-ShowGroupCenter"]
                            }
                        },
                        "ContextDataKeys": {"Version": 3}
                    }
                    this.$service.ExecuteRuleNode(params).then(res => {
                        resolve(res);
                    })
                })
            },
            async onSelectEventType(item) {
                const query = {
                    eventType: item.eventTypeCode
                }
                if (getCookie('tenant') === 'pfizer') {
                    const EVENT_TYPE_GROUP_MEETING = 'groupMeeting';
                    // const EVENT_TYPE_TMIS = 'TMIS';
                    if (item.eventTypeCode === EVENT_TYPE_GROUP_MEETING) { // 辉瑞的还需要判定会议中心的权限
                        try {
                            const isAuth = await this.isPfizerConferenceCenterPermission();
                            if (!isAuth.success) {
                                return;
                            }
                        } catch (error) {
                            console.error('Error会议中心权限问题:', error);
                            return;
                        }
                    }
                    // if(item.eventTypeCode === EVENT_TYPE_TMIS) { // 老毕：当有mapp的时候，yes内不展示创建会议按钮
                    //     query.isShowYesAddBtn = !this.options.options.find(i => i.eventTypeCode === 'MAPP');
                    // }
                }
                this.$smartStorage.set("homeQueryListExpr", {
                    listExpr: item.queryListExpr,
                    cardExpr: item.queryListCard
                });
                this.$smartStorage.set("currentEventTypeTab", 0);
                this.$smartStorage.set("selectedEventType", item.eventTypeCode);
          
                
                this.$router.push({
                    path: "/eventList",
                    query
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .chooseEventType {
        position: relative;
        height: 2.76rem;
        
        .chooseEventTypeTag {
            position: absolute;
            left: 0.27rem;
            top: 0.57rem;
            
            p {
                color: #14161c;
                text-align: left;
                font-size: 0.17rem;
            }
            
            div {
                color: #90949a;
                text-align: left;
                font-size: 0.1rem;
            }
        }
        
        .chooseEventTypeImg {
            height: 2.76rem;
            width: 100%;
            display: block;
        }
    }
    
    .cnt {
        padding: 0 0.1rem;
        position: absolute;
        top: 2rem;
        width: 100vw;
        box-sizing: border-box;
        display: flex; // 触发BFC
        flex-direction: column;
        
        &_other {
            font-size: 0.16rem;
            font-weight: bold;
            margin-bottom: 0.16rem;
            margin-top: 0.08rem;
            line-height: 1;
        }
        
        &-item {
            width: 100%;
            padding: 0.18rem 0.25rem;
            box-sizing: border-box;
            background-color: #FFFFFF;
            box-shadow: 0px 0.02rem 0.08rem 0px rgba(0, 0, 0, 0.1);
            border-radius: 0.04rem;
            display: flex;
            align-items: center;
            margin-bottom: 0.08rem;
            
            .left-icon {
                width: 0.27rem;
                height: 0.27rem;
            }
            
            .right-box {
                margin-left: 0.23rem;
                
                .title {
                    font-size: 0.16rem;
                    font-weight: 500;
                    color: #13161A;
                }
                
                .desc {
                    font-size: 0.14rem;
                    font-weight: 500;
                    color: #90939A;
                }
            }
        }
    }
</style>
