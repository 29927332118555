var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.setup
    ? _c("div", [
        _c("div", { staticClass: "chooseEventType" }, [
          _vm._m(0),
          _c("img", {
            staticClass: "chooseEventTypeImg",
            attrs: { src: _vm.backgroundImg },
          }),
        ]),
        _c(
          "div",
          { staticClass: "cnt" },
          [
            _vm._l(_vm.options.options, function (item, index) {
              return [
                item.eventTypeCode === "groupMeeting" &&
                _vm.getCookie("tenant") === "pfizer"
                  ? _c("div", { key: index, staticClass: "cnt_other" }, [
                      _vm._v("其他 "),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    key: index,
                    staticClass: "cnt-item",
                    on: {
                      click: function ($event) {
                        return _vm.onSelectEventType(item)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "left-icon" },
                      [
                        _c("i-icon", {
                          attrs: {
                            size: item.iconSize,
                            color: item.iconColor,
                            name: item.icon,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "right-box" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.eventTypeName)),
                      ]),
                      _c("div", { staticClass: "desc" }, [
                        _vm._v(_vm._s(item.eventTypeTitle)),
                      ]),
                    ]),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chooseEventTypeTag" }, [
      _c("p", { staticStyle: { "font-size": "24px", "font-weight": "bold" } }, [
        _vm._v("会议入口"),
      ]),
      _c("div", [_vm._v("CONFERENCE ENTRANCE")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }