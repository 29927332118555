import { httpService} from "@/services/httpService";
import beforePfizerGetCategory from "@/utils/beforePfizerGetCategory";
import {
    SmartStorage,
} from 'smart-core-util'
import {getCookie} from "tiny-cookie";
const isPfizer = getCookie('tenant') === 'pfizer';

export default async function () {
    let params = {
        collection: "cfg-ievent-mice-category-operation",
        filter: {
            tenantCode: getCookie("tenant") || SmartStorage.get("tenant"),
            tenantId: getCookie("tenant_id") || "",
        },
    };
    return new Promise(resolve => {
        httpService.GetSettings(params).then(async res => {
            // resolve(res);
            if(isPfizer) {
                res.content.options = await beforePfizerGetCategory(res.content.options);
                resolve({...res});
            }else {
                resolve(res)
            }
        })
    })
}
